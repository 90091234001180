import * as React from "react";
import { graphql } from "gatsby";
import { getImage, StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Books from "../components/allBooks";
import { Box, Heading, Text } from "@chakra-ui/layout";
import { keyframes, usePrefersReducedMotion } from "@chakra-ui/react";

const shimmer = keyframes`
  to {
		background-position: 200% center;
	}
`;
const rotation = keyframes`
  to {
		transform: rotate(15deg); 
	}
`;

const SelfCareLandingPage = ({ data }) => {
  const books = data.allMarkdownRemark.nodes;

  const prefersReducedMotion = usePrefersReducedMotion();

  const animation = prefersReducedMotion
    ? undefined
    : `${shimmer} 5s ease-out infinite`;

  const animationRotation = prefersReducedMotion
    ? undefined
    : `${rotation} 20s linear infinite alternate`;

  return (
    <Layout
      title="Herakles Verlag - Self Care Week"
      image="/images/twitter-self-care.png"
      description="Nach eineinhalb Jahren Stillstand setzt sich die Welt wieder in
      Bewegung. Das ist schön und fordernd zugleich. Um Ihnen zu helfen,
      wieder bewusst und gestärkt in den nächsten Lebensabschnitt zu
      starten, stellen wir hier unsere sieben liebsten Ratgeber vor, die im
      Jahre 2021 beim Herakles Verlag erschienen sind."
    >
      <Box position="relative" maxHeight="sm" maxWidth="5xl" overflow="hidden">
        <StaticImage
          src="../../static/images/wellness.jpg"
          position="absolute"
          top="0"
          left="0"
        ></StaticImage>
        <Box
          position="absolute"
          w="6xl"
          opacity="0.3"
          top="-50%"
          left={["-50%", "-20%"]}
          css={"mix-blend-mode: color-burn"}
          overflow="hidden"
          animation={animationRotation}
        >
          <StaticImage src="../../static/images/starry-night.png"></StaticImage>
        </Box>

        <Heading
          fontSize={["4xl", "6xl", "8xl"]}
          position="absolute"
          color="white"
          top="30%"
          width="100%"
          textAlign="center"
          textShadow="0px 0px 20px rgba(66,88,180,1)"
          animation={animation}
        >
          🌱 Self-Care
        </Heading>
        <Heading
          fontSize={["4xl", "6xl", "8xl"]}
          position="absolute"
          background="linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(220,249,255,1) 8%, rgba(255,255,255,1) 22%, rgba(229,229,247,1) 42%, rgba(255,255,255,1) 57%, rgba(216,255,244,1) 81%, rgba(255,255,255,1) 100%);"
          backgroundClip="text"
          backgroundSize="200% auto"
          top="30%"
          width="100%"
          textAlign="center"
          animation={animation}
        >
          🌱 Self-Care
        </Heading>
      </Box>
      <Box w="100%">
        <Text
          fontFamily="baskervville"
          fontSize="2xl"
          w="100%"
          textAlign="center"
          px="10%"
        >
          »Ein gutes Buch kann beste Freundin und Therapeut zugleich sein«{" "}
          <br></br>
        </Text>
        <Text
          w="100%"
          textAlign="center"
          px="10%"
          fontFamily="caveat"
          fontSize="4xl"
          pb="8"
        >
          Gerda Brauer
        </Text>{" "}
        <Text px="10%">
          Nach eineinhalb Jahren Stillstand setzt sich die Welt wieder in
          Bewegung. Das ist schön und fordernd zugleich. Um Ihnen zu helfen,
          wieder bewusst und gestärkt in den nächsten Lebensabschnitt zu
          starten, stellen wir hier unsere sieben liebsten Ratgeber vor, die im
          Jahre 2021 beim Herakles Verlag erschienen sind.
        </Text>
      </Box>

      <Books
        books={books}
        headline="Unsere liebsten Ratgeber 2021"
        showFilter
      ></Books>
    </Layout>
  );
};

export default SelfCareLandingPage;

export const query = graphql`
  query SelfCareLandingPage {
    allMarkdownRemark(
      filter: {
        frontmatter: { layout: { eq: "book" }, tags: { eq: "Ratgeber" } }
      }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      nodes {
        frontmatter {
          title
          author
          abstract
          tags
          cover {
            childImageSharp {
              gatsbyImageData(
                width: 154
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          readingSample
        }
        fields {
          slug
        }
      }
    }
  }
`;
